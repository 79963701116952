import {
	init,
	handleErrorWithSentry,
	Replay,
	BrowserTracing,
	showReportDialog,
	BrowserProfilingIntegration
} from '@sentry/sveltekit';
import { dev } from '$app/environment';

init({
	dsn: 'https://540608b01a9f4f05adacbbc66d346eaf@o4504769389068288.ingest.sentry.io/4506174321131520',
	tracesSampleRate: 1.0,
	// https://outback-api-nudpndrtra-as.a.run.app/api/
	// https://backgrounder-api-d6vf2witia-df.a.run.app/api/v1
	tracePropagationTargets: [
		'localhost',
		/^https:\/\/outback-api-nudpndrtra-as\.a\.run\.app\/api/,
		/^https:\/\/backgrounder-api-d6vf2witia-df\.a\.run\.app\/api\/v1/,
		/^https:\/\/outback\.fly\.dev\/api/
	],
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		new Replay({
			maskAllText: false,
			maskAllInputs: false,
			mask: ['#password', '#confirmPassword']
		}),
		new BrowserTracing(),
		new BrowserProfilingIntegration()
	],
	beforeSend(event) {
		// show only if the error is 500, 504
		const ERROR_CODES_TO_SHOW_REPORT_DIALOG = [500, 504];
		if (event.exception && event.exception.values?.[0]?.type === 'Error') {
			const errorValue = event.exception.values?.[0]?.value ?? '0';
			const errorCode = parseInt(errorValue);
			if (ERROR_CODES_TO_SHOW_REPORT_DIALOG.includes(errorCode)) {
				showReportDialog({ eventId: event.event_id });
			}
		}

		return event;
	},
	environment: dev ? 'development' : 'production'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
