import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0,5,2];

export const dictionary = {
		"/": [~9],
		"/app": [~31,[5],[6]],
		"/app/document/[documentId]": [~32,[5],[6]],
		"/app/graphs": [33,[5],[6]],
		"/app/logout": [~34,[5],[6]],
		"/app/search/[...searchSlug]": [35,[5,7],[6]],
		"/auth": [~36,[8]],
		"/auth/forgot-password": [37,[8]],
		"/auth/login": [38,[8]],
		"/auth/reset-password/[token]": [~39,[8]],
		"/auth/signup": [40,[8]],
		"/auth/verify-email/[token]": [~41,[8]],
		"/(backgrounder)/backgrounder": [~10,[2,3]],
		"/(backgrounder)/backgrounder/search/[query]": [~12,[2,3]],
		"/(backgrounder)/backgrounder/[entityRoute]": [~11,[2,3]],
		"/(backgrounder)/bank": [~13,[2]],
		"/(backgrounder)/bank/[id]": [~14,[2]],
		"/(legal)/california-resident-notice-at-collection": [27,[4]],
		"/(backgrounder)/company": [~15,[2]],
		"/(backgrounder)/company/[id]": [~16,[2]],
		"/(backgrounder)/media": [~17,[2]],
		"/(backgrounder)/media/[id]": [~18,[2]],
		"/(backgrounder)/person": [~19,[2]],
		"/(backgrounder)/person/[id]": [~20,[2]],
		"/(backgrounder)/pilot": [~21,[2]],
		"/(backgrounder)/pilot/[id]": [~22,[2]],
		"/(legal)/privacy-policies": [28,[4]],
		"/(legal)/refund-policy": [29,[4]],
		"/(backgrounder)/region": [~23,[2]],
		"/(backgrounder)/region/[id]": [~24,[2]],
		"/(backgrounder)/state-body": [~25,[2]],
		"/(backgrounder)/state-body/[id]": [~26,[2]],
		"/(legal)/terms-and-conditions": [30,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';